<template>
  <div>
    <CyAlert
      v-if="isMaxMembersReached"
      theme="warning"
      :title="$t('licence.reachedMaxUsersCapacity')"
      :content="$t('licence.cannotInviteMembers')"
      :button-label="$t('licence.contactYourAdmin')"
      @click="navigateToContactAdmin"/>
    <CyAlert
      theme="error"
      :content="errors"/>

    <!--
      Members datatable
    -->
    <CyDataTableYdApi
      ref="cyDataTable"
      :bulk="hasBulkModeEnabled"
      :fetch-available="[
        {
          keyPath: 'members',
          extraParams: [{ 'invitation_state[in]': 'accepted' }],
        },
        {
          keyPath: 'invites',
          extraParams: [{ 'invitation_state[in]': 'pending,declined' }],
        }]"
      :headers="$static.headers"
      :transform-items="$static.transformItems"
      :link-builder="getLinkTarget"
      :searchable-fields="$static.searchableFields"
      key-field="id">
      <template #table-cmp-header-actions="{ selected }">
        <CyButton
          v-if="!_.isEmpty(selected)"
          theme="error"
          icon="delete"
          @click="openDeleteModal(selected)">
          {{ $tc('removeMembersBtn', selected.length, { nb: selected.length }) }}
        </CyButton>

        <div v-else>
          <CyButton
            v-if="!_.isEmpty(_.concat(pendingInvites, declinedInvites))"
            variant="tertiary"
            theme="primary"
            :to="{ name: 'pendingInvites' }"
            class="pending-invites__link">
            {{ $tc('seePendingInvitesBtn', pendingInvites.length) }}
          </CyButton>

          <CyButton
            v-has-rights-to="'InviteUserToOrgMember'"
            icon="add"
            :loading="fetchingLicence"
            :disabled="isMaxMembersReached"
            :to="{ name: 'memberInvite' }"
            data-cy="invite-to-org"
            @click="$gtm.trackEvent($static.gtmOrganizationsEvents.organizationsMembersInviteMembers)">
            {{ $t('routes.membersInvite') }}
          </CyButton>
        </div>

        <!--
          Delete modal
        -->
        <CyModal
          v-if="showDeleteModal"
          :header-title="$tc('confirmRemoveTitle', toDelete.length, { nb: toDelete.length })"
          :action-btn-func="unassignOrgMembers"
          :cancel-btn-func="() => $toggle.showDeleteModal(false)"
          :action-btn-text="$tc('removeMembersBtn', toDelete.length, { nb: selected.length })"
          modal-type="delete">
          <p>
            {{ $t('forms.cannotBeUndone') }}
            <span v-html="$tc('areYouSure', toDelete.length, { item: toDelete[0].name })"/>
          </p>
          <ul class="items-to-delete">
            <li
              v-for="item of toDelete"
              :key="item.id">
              <CyMember :member="item"/>
            </li>
          </ul>
        </CyModal>
      </template>

      <template #table-cmp-body-row="{ props: { item } }">
        <td>
          <CyAvatar
            :item="item"
            sm/>
        </td>
        <td>{{ item.displayName }}</td>
        <td>{{ item.email }}</td>
        <td>
          <CyButton
            :to="{
              name: 'role',
              params: { roleCanonical: item.role.canonical },
            }"
            :disabled="!canGoToRole(item)"
            :readonly="!canGoToRole(item)"
            variant="tertiary"
            theme="grey"
            class="font-weight-regular"
            @click.stop>
            {{ item.role.name }}
          </CyButton>
        </td>
        <td>
          {{ item.mfa_enabled ? $t('enabled'):$t('disabled') }}
        </td>
        <td>
          <CyButton
            v-if="item.invited_by"
            :to="{
              name: 'member',
              params: { id: String(item.invited_by.id) },
            }"
            :disabled="!canGoToMember(item)"
            :readonly="!canGoToMember(item)"
            variant="tertiary"
            theme="grey"
            member-link
            @click.stop>
            <CyMember :member="item.invited_by"/>
          </CyButton>
        </td>
        <td v-if="item.last_login_at">
          {{ $date.$formatTimeAgo(item.last_login_at) }}
        </td>
      </template>
    </CyDataTableYdApi>
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex'
import CyDataTableYdApi from '@/components/data-table-yd-api.vue'
import { constructBreadcrumb, displayName } from '@/utils/helpers'
import { gtmOrganizationsEvents } from '@/utils/helpers/analytics'

export default {
  name: 'CyPageMembers',
  components: {
    CyDataTableYdApi,
  },
  breadcrumb () {
    return constructBreadcrumb(this.$options.name, this.$t('routes.members'))
  },
  data: () => ({
    showDeleteModal: false,
    toDelete: [],
    fetchingLicence: false,
  }),
  computed: {
    ...mapState('organization', {
      members: (state) => state.available.members,
      invites: (state) => state.available.invites,
    }),
    ...mapState('organization/member', {
      membersErrors: (state) => state.errors,
    }),
    ...mapState('organization/licence', {
      licence: (state) => state.detail,
      licenceErrors: (state) => state.errors,
    }),
    ...mapGetters('organization/licence', [
      'isMaxMembersReached',
    ]),
    $static () {
      return {
        gtmOrganizationsEvents,
        headers: [
          {
            text: '',
            value: 'img',
            align: 'center',
            sortable: false,
            width: '10px',
          },
          {
            text: this.$t('name'),
            value: 'displayName',
            align: 'left',
            sort: (a, b) => {
              return a.localeCompare(b)
            },
          },
          {
            text: this.$t('forms.fieldEmail'),
            value: 'email',
            align: 'left',
          },
          {
            text: this.$t('forms.role'),
            align: 'left',
            value: 'role',
            sort: (a, b) => {
              return a.name.localeCompare(b.name)
            },
          },
          {
            text: this.$t('mfa'),
            align: 'left',
            value: 'mfa_enabled',
          },
          {
            text: this.$t('invitedBy'),
            align: 'left',
            value: 'invited_by',
            sort: (a, b) => {
              return _.get(a, 'id', '').localeCompare(_.get(b, 'id', ''))
            },
          },
          {
            text: this.$t('lastLogin'),
            align: 'left',
            value: 'last_login_at',
          },
        ],
        searchableFields: [
          {
            name: 'displayName',
            label: this.$t('name'),
          },
          {
            name: 'email',
            label: this.$t('forms.fieldEmail'),
          },
          {
            name: 'roleName',
            label: this.$t('forms.role'),
          },
        ],
        transformItems (item) {
          return { ...item, displayName: displayName(item), roleName: item.role.name }
        },
      }
    },
    errors () {
      return [...this.membersErrors, ...this.licenceErrors]
    },
    hasBulkModeEnabled () {
      const canDelete = this.$cycloid.permissions.canDisplay('RemoveOrgMember')
      return canDelete && !_.isEmpty(this.members)
    },
    pendingInvites () {
      return _.filter(this.invites, { invitation_state: 'pending' })
    },
    declinedInvites () {
      return _.filter(this.invites, { invitation_state: 'declined' })
    },
  },
  methods: {
    ...mapActions('organization', [
      'UNASSIGN_ORG_MEMBERS',
    ]),
    ...mapActions('organization/licence', [
      'GET_LICENCE',
    ]),
    async getLicence () {
      this.fetchingLicence = true
      await this.GET_LICENCE()
      this.fetchingLicence = false
    },
    getLinkTarget ({ id } = {}) {
      return {
        name: 'member',
        params: { id: String(id) },
      }
    },
    navigateToContactAdmin () {
      if (!this.licence?.email_address) return
      window.location.assign(`mailto:${this.licence.email_address}`)
    },
    async unassignOrgMembers () {
      const ids = _.map(this.toDelete, 'id')
      await this.UNASSIGN_ORG_MEMBERS({ ids })
      this.$toggle.showDeleteModal(false)
      this.toDelete = []
      this.$refs.cyDataTable.retrieveItems({ clearErrors: false, clearSelected: true })
      this.getLicence()
    },
    openDeleteModal (toDelete) {
      this.showDeleteModal = true
      this.toDelete = toDelete
    },
    canGoToRole ({ canonical }) {
      return this.$cycloid.permissions.canDisplay('GetRole', canonical)
    },
    canGoToMember ({ id }) {
      return this.$cycloid.permissions.canDisplay('GetOrgMember', id)
    },
  },
  i18n: {
    messages: {
      en: {
        title: '@:routes.members',
        areYouSure: 'Are you really sure you want to remove this member? | Are you really sure you want to remove the following members?',
        confirmRemoveTitle: '@:forms.btnRemove member | @:forms.btnRemove members',
        invitedBy: 'Invited by',
        lastLogin: 'Last login',
        mfa: 'Multi-Factor Authentication',
        removeMembersBtn: '@:forms.btnRemove {nb} member | @:forms.btnRemove {nb} members',
        seePendingInvitesBtn: 'See invitations | See {n} pending invitation | See {n} pending invitations',
      },
      es: {
        title: '@:routes.members',
        areYouSure: '¿Está realmente seguro de que desea eliminar este miembro? | ¿Está realmente seguro de que desea eliminar los siguientes miembros?',
        confirmRemoveTitle: '@:forms.btnRemove miembro | @:forms.btnRemove miembros',
        invitedBy: 'Invitado por',
        lastLogin: 'Último acceso',
        mfa: 'Autenticación de Múltiples Factores',
        removeMembersBtn: '@:forms.btnRemove {nb} miembro | @:forms.btnRemove {nb} miembros',
        seePendingInvitesBtn: 'Ver invitaciones | Ver {n} invitación pendiente | Ver {n} invitaciones pendientes',
      },
      fr: {
        title: '@:routes.members',
        areYouSure: 'Êtes-vous vraiment sûr de vouloir retirer ce membre ? | Êtes-vous vraiment sûr de vouloir retirer les membres suivants ?',
        confirmRemoveTitle: '@:forms.btnRemove un membre | @:forms.btnRemove des membres',
        invitedBy: 'Invité par',
        lastLogin: 'Dernière connexion',
        mfa: 'Authentification Multi-Facteurs',
        removeMembersBtn: '@:forms.btnRemove {nb} membre | @:forms.btnRemove {nb} membres',
        seePendingInvitesBtn: 'Voir les invitations | Voir {n} invitation en attente | Voir {n} invitations en attente',
      },
    },
  },
}
</script>
